import Flickity from 'vue-flickity'
import CourseCard from '@/components/CourseCard/index.vue'
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'CourseCarousal',
  props: {
    cardData: {
      type: Array
    },
    mainHeading: {
      type: String
    },
    feedHeading: {
      type: String,
      required: true
    },
    cardCss: {
      type: Object,
      required: true
    }
  },
  components: { Flickity, CourseCard },
  data () {
    return {
      flickityOptions: {
        groupCells: 4,
        initialIndex: 0,
        prevNextButtons: true,
        pageDots: false,
        contain: true,
        draggable: false,
        cellAlign: 'left'
        // any options from Flickity can be used
      },
      currentActiveIndex: 0,
      coursesArray: []

    }
  },
  created () {
    this.coursesArray = this.cardData
    this.getCurrentViewportWidth()
  },
  mounted () {
  },
  methods: {
    ...mapActions([
      'GET_MOST_POPULAR',
      'GET_MOST_LIKED'
    ]),
    showPrevElement () {
      this.$refs.flickity.previous()
    },
    showNextElement () {
      this.$refs.flickity.next()
    },
    getCurrentViewportWidth () {
      let device
      if (window.innerWidth < 550) {
        this.flickityOptions.groupCells = 1
        device = 'MOBILE'
      }

      if (window.innerWidth > 550 && window.innerWidth <= 1024) {
        this.flickityOptions.groupCells = 3
        device = 'WEB'
      }
      return device
    }
  },
  computed: {
    ...mapGetters([
      'getMostLiked',
      'getMostPopular',
      'getMostLikedResponse',
      'getMostPopularResponse'
    ]),
    cssProps () {
      return {
        '--feedHeading-color': this.cardCss.feedColor,
        '--mainHeading-color': this.cardCss.mainColor
      }
    }
  }
}
