import AwesomeVueStarRating from '@/components/AwesomeVueStarRating/AwesomeVueStarRating.vue'
import { mapActions, mapGetters, mapMutations } from 'vuex'

export default {
  name: 'CourseCard',
  props: {
    courseData: {},
    cardData: {}
  },
  components: {
    AwesomeVueStarRating
  },
  data () {
    return {
      rating: 0,
      totalTime: 0,
      likes: 0,
      thumbnailUrl: '',
      course_title: '',
      color1: '',
      color2: '',
      courseDescription: '',
      topic: '',
      comments: null,
      publisher: '',
      category: '',
      contentType: '',
      staticUrl: 'https://static.overlay-tech.com/assets/a3af2896-e1a2-4992-a2a9-cd4d2497593a.png',
      starRatingData: {
        hasresults: false,
        hasdescription: false,
        starsize: '1x', // [xs,lg,1x,2x,3x,4x,5x,6x,7x,8x,9x,10x],
        maxstars: 5,
        disabled: true
      }
    }
  },
  created () {
    // this.rating = this.courseData.rating
    this.comments = this.courseData.content.comments
    this.rating = this.courseData.content.rating.averageRating
    this.likes = this.courseData.content.likes
    this.totalTime = Math.floor(this.courseData.content.timeTotal / 60)
    this.thumbnailUrl = this.courseData.content.thumbnailUrl
    this.courseTitle = this.courseData.content.title
    this.courseDescription = this.courseData.content.description
    this.topic = this.courseData.content.publisher.name
    this.publisher = this.courseData.content.publisher.name
    this.category = this.courseData.content.category.name
    this.contentType = this.courseData.content.contentType
    if (this.courseData.content.bar_color) {
      this.color1 = this.courseData.content.bar_color.color_1
      this.color2 = this.courseData.content.bar_color.color_2
    } else {
      this.color1 = 'red'
      this.color2 = 'orange'
    }
  },
  methods: {
    ...mapActions([
      'RESET_STATE'
    ]),
    ...mapMutations([
      'setLoggedUser',
      'setPage',
      'setType',
      'setAction',
      'setName',
      'setContentId',
      'setCategory',
      'setPublisherId',
      'setPublisherName',
      'setRating',
      'setReview',
      'setLike',
      'setBookmark',
      'setTopic',
      'setLevel',
      'setIsInfluencer',
      'setUserId'

    ]),
    playCourse () {
      let payload = {
        contentId: this.courseData.content.id
        // thumbnailUrl: this.thumbnailUrl,
        // subtitleUrl: this.courseData.content.subtitleUrl,
        // contentUrls: this.courseData.content.contentUrl,
        // timeSpent: this.timeSpent
      }
      // if (payload.subtitleUrl && payload.contentUrls) {
      if (payload.contentId) {
        if (this.contentType === 'TEXT') {
          this.$router.push({ path: `/article/${payload.contentId}` })
        } else {
          this.$router.push({ path: `/video-content/${payload.contentId}` })
        }
      }
      this.bombIsAway('contentCardClick', 'open')

      // eslint-disable-next-line no-undef
      // divolte.signal('contentCardClick', { action: 'open', contentId: this.courseData.content.id })
      // console.log('Video Data ' + JSON.stringify(payload))
    },
    bombIsAway (eventType, action) {
      console.log(eventType + ' ' + action)
      this.setContentId(this.courseData.content.id)
      this.setAction(action)
      this.setName(this.courseData.content.title)
      this.setCategory(this.courseData.content.category.name)
      this.setPublisherName(this.courseData.content.publisher.name)
      console.log('Conetnt Response' + JSON.stringify(this.courseData.content))
      if (this.courseData.content.publisher.isInfluencerContent) {
        console.log('Is influencer')
        this.setIsInfluencer(true)
        this.setUserId(this.courseData.content.createdBy)
      }
      this.setPublisherId(this.courseData.content.publisher.id)
      this.setLike(this.courseData.content.likes)
      this.setBookmark('false')
      this.setReview(null)
      this.setRating(this.courseData.content.rating.averageRating)
      this.setLevel('Micro')

      console.log(this.getAnalyticsState)
      // eslint-disable-next-line no-undef
      divolte.signal(eventType, this.getAnalyticsState)
    }
  },
  computed: {
    ...mapGetters([
      'getAnalyticsState'
    ]),
    cssProps () {
      return {
        '--card-color': this.cardData.bgColor,
        '--font-color': this.cardData.fontColor,
        '--bar-color1': this.color1,
        '--bar-color2': this.color2
      }
    }
  }
}
